<template>
  <div class="contractor-invite d-flex flex-column">
    <v-row no-gutters dense class="d-flex flex-grow-0">
      <v-col cols="12" class="pa-0">
        <p class="my-heading">Add Contractor</p>
      </v-col>
    </v-row>
    <v-row no-gutters dense align="stretch" class="d-flex">
      <v-card min-height="100%" min-width="100%" class="my-card d-flex">
        <v-row no-gutters dense class="d-flex flex-column">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row no-gutters dense class="d-flex">
              <v-col cols="12">
                <p class="heading-sf20 pb-4">Contractor Information</p>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <app-user-email
                  v-model="contractor.email"
                  label="Email"
                  placeholder="Enter valid email address"
                  type="user"
                ></app-user-email>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <v-row class="p-0">
                  <v-col cols="12" class="pb-0">
                    <label>Password</label>
                  </v-col>
                  <v-col cols="12" class="pt-1">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      :hide-details="Show"
                      height="48"
                      color="#CACEDA"
                      type="password"
                      placeholder="Password"
                      v-model="contractor.password"
                      :rules="passwordRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <app-phone-number
                  v-model="contractor.phone"
                  label="Phone"
                  placeholder="Enter contact number"
                  :rules="phoneRules"
                ></app-phone-number>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <app-text-box
                  v-model="contractor.first_name"
                  label="First Name"
                  placeholder="First name"
                  :rules="firstNameRules"
                ></app-text-box>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <app-text-box
                  v-model="contractor.last_name"
                  label="Last Name"
                  placeholder="Last name"
                  :rules="lastNameRules"
                ></app-text-box>
              </v-col>
              <v-col cols class="col-md-6 col-sm-12 px-2">
                <v-row class="pb-3" dense>
                  <v-col cols="12" class="pt-0">
                    <label>Trial Plan</label>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-select
                      single-line
                      outlined
                      dense
                      height="48"
                      color="#CACEDA"
                      required
                      :items="trialItems"
                      :itemText="'title'"
                      :itemValue="'value'"
                      label="Trial"
                      v-model="contractor.trial"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="contractor.trial"
                cols
                class="col-md-6 col-sm-12 px-2"
              >
                <v-row class="pb-3" dense>
                  <v-col cols="12" class="pt-0">
                    <label>Plan</label>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-select
                      single-line
                      outlined
                      dense
                      height="48"
                      color="#CACEDA"
                      class=""
                      :items="plans"
                      :itemText="'title'"
                      :itemValue="'value'"
                      label="Plan"
                      v-model="contractor.price_id"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters dense align="stretch" class="d-flex">
            <v-col
              v-if="error_message != ''"
              class="d-flex align-center mt-auto p-0 custom-error"
              cols="12"
              >{{ this.error_message }}</v-col
            >
            <v-col cols="12" class="d-flex justify-end mt-auto">
              <v-btn
                class="my-auto-btn my_elevation"
                id="my_elevation"
                @click="inviteContractor()"
                :disabled="!valid"
                depressed
                >Create</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
const TextBox = () => import("../../mycomponents/MyTextBox");
const PhoneNumber = () => import("../../mycomponents/MyPhoneNumber");
const UserEmail = () => import("../../mycomponents/UserEmail");
import { inviteContractor } from "../../services/contractorservice";
import { getPlans } from "../../services/contractorservice";

export default {
  components: {
    AppTextBox: TextBox,
    AppPhoneNumber: PhoneNumber,
    AppUserEmail: UserEmail,
  },
  data() {
    return {
      valid: true,
      plans: [],
      error_message: "",
      trialItems: [
        { title: "No Trial", value: 0 },
        { title: "1 Month", value: 1 },
        { title: "2 Months", value: 2 },
        { title: "3 Months", value: 3 },
        { title: "4 Months", value: 4 },
        { title: "5 Months", value: 5 },
        { title: "6 Months", value: 6 },
      ],
      contractor: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        trial: 0,
        price_id: "",
      },
      firstNameRules: [
        (v) =>
          v && v.length > 0
            ? /^[A-Za-z]+$/.test(v) || "Input alphabet character only"
            : !v,
      ],
      lastNameRules: [
        (v) =>
          v && v.length > 0
            ? /^[A-Za-z]+$/.test(v) || "Input alphabet character only"
            : !v,
      ],
      phoneRules: [
        (v) =>
          v && v.length > 0
            ? (v && v.length >= 14 && v.length <= 14) ||
              "Phone number must be 10 digit"
            : !v,
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) || "Password must be greater than 8 characters",
      ],
    };
  },

  created() {
    this.$store.watch(
      (state) => {
        return state.auth.error;
      },
      () => {
        if (this.$router.currentRoute.path === "/contractor-create") {
          this.error_message = this.$store.state.auth.error;
        }
      },
      { deep: true }
    );
  },

  async mounted() {
    await this.getPlans();
  },

  computed: {
    Show() {
      return (this.show = !this.valid ? false : true);
    },
  },

  methods: {
    async getPlans() {
      const resp = await getPlans();
      const plans = [];
      if (resp) {
        for (let item = 0; item < resp.dataset.length; item++) {
          const element = resp.dataset[item];
          plans.push({
            title: element?.name,
            value: element?.monthly?.id,
          });
        }
      }
      this.plans = plans;
      this.contractor.price_id = plans[0]?.value;
    },
    async inviteContractor() {
      if (this.$refs.form.validate()) {
        this.$store.commit("errorMessage", "");
        this.error_message = "";

        let ob = {
          fullname:
            this.contractor.first_name + " " + this.contractor.last_name,
          email: this.contractor.email,
          password: this.contractor.password,
          phone: this.contractor.phone,
          trial: this.contractor.trial,
          price_id: this.contractor.price_id,
        };

        let data = await inviteContractor(ob);

        if (!data.data) {
          this.error_message = this.$store.state.auth.error;
        } else {
          let alert = {
            show: true,
            message: "Contractor created successfully",
            type: "success",
          };
          this.$store.commit("showAlert", alert);
          this.$refs.form.reset();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contractor-invite {
  height: inherit;
}
</style>
